<!-- @format -->

<template>
  <div>
    <div class="grid grid-cols-1 gap-6">
      <MopfButtonsGroup :buttons="buttons" :svgIcon="contract_edit" />
    </div>
    <div class="flex gap-8 pt-8">
      <div class="w-full" v-for="(data, key) in complaintData" :key="key">
        <ComplaintsRadialProgressBar
          :cardData="data"
          :loader="loaderProgressBar"
        />
      </div>
    </div>
    <div>
      <ComplaintStackBarChart
        :data="complaintAgainstPracticesStats"
        @at-filter-change="practiceFilterChange"
      />
    </div>
    <div class="pt-8 flex gap-8">
      <SubjectGroupList
        class="w-2/5"
        :subjectList="subjectGroupStats"
        @at-filter-change="subjectGroupFilterChange"
      />
      <StaffGroupsTable
        class="w-3/5"
        :staffGroupStats="staffGroupStats"
        @at-filter-change="staffGroupFilterChange"
      />
    </div>
    <div class="pt-8 flex gap-8">
      <AgeGroupsChart
        class="w-3/5"
        :ageGroupStats="ageGroupStats"
        @at-filter-change="ageGroupFilterChange"
      />
      <ComplainantRelationshipChart
        class="w-2/5"
        :patientRelationshipStats="patientRelationshipGroupStats"
        @at-filter-change="patientGroupFilterChange"
      />
    </div>
    <div class="pt-8">
      <ComplaintsList
        :tableData="AllComplaints"
        :loader="loader"
        :filtersApplied="filtersApplied"
        @at-tab-changed="tabChanged"
        @at-page-changed="pageChanged"
        @at-filter-select="appliedFilters"
        @at-clear-filters="clearFilters"
        @at-remove-filter="removeFilters"
        @at-search-applied="applySearch"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import ComplaintsRadialProgressBar from "@/components/newUi/Complaints/ComplaintsRadialProgressBar.vue";
import ComplaintStackBarChart from "@/components/newUi/Complaints/ComplaintsStackBarChart.vue";
import MopfButtonsGroup from "@/components/newUi/baseComponents/MopfButtonsGroup.vue";
import SubjectGroupList from "@/components/newUi/Complaints/SubjectGroupList.vue";
import StaffGroupsTable from "@/components/newUi/Complaints/StaffGroupsTable.vue";
import AgeGroupsChart from "@/components/newUi/Complaints/AgeGroupsChart.vue";
import ComplainantRelationshipChart from "@/components/newUi/Complaints/ComplainantRelationshipChart.vue";
import ComplaintsList from "@/components/newUi/Complaints/ComplaintsList.vue";
import contract_edit from "@/assets/icons/contract_edit.svg";

const activeTab = ref(0);
const store = useStore();
const page = ref(1);
const loader = ref(false);
const loaderProgressBar = ref(false);
const subjectGroupFilter = ref("daily");
const practicesFilter = ref("daily");
const staffGroupFilter = ref("daily");
const ageGroupFilter = ref("daily");
const patientGroupFilter = ref("daily");
const subjectAreaFilters = ref([]);
const staffGroupFilters = ref([]);
const practiceFilters = ref([]);
const patientAgeGroupFilters = ref([]);
const dateFilters = ref([]);
const filterPayload = ref();
const isFilterApplied = ref(false);
const searchQuery = ref();

const filtersApplied = computed(() => {
  return {
    isFilter: isFilterApplied.value,
    filters: filterPayload.value,
    search: searchQuery.value,
  };
});

const appliedFilters = (filter) => {
  isFilterApplied.value = true;
  page.value = 1;
  switch (filter.filterKey) {
    case "Subject Area":
      subjectAreaFilters.value.push(filter.filterId);

      return;

    case "Staff Group":
      staffGroupFilters.value.push(filter.filterId);

      return;

    case "Practice":
      practiceFilters.value.push(filter.filterId);

      return;

    case "Patient Age Group":
      patientAgeGroupFilters.value.push(
        filter.filterId.includes("Age 60+") ? "Age 60" : filter.filterId
      );
      return;

    case "Date":
      dateFilters.value.push({
        startDate: filter.startDate,
        endDate: filter.endDate,
      });
      return;
  }
};

const removeFilters = (filter) => {
  page.value = 1;
  switch (filter.filterKey) {
    case "Subject Area": {
      const subjectAreaIndex = subjectAreaFilters.value.findIndex(
        (subjectAreaFilter) => {
          return subjectAreaFilter === filter.filterId;
        }
      );
      if (subjectAreaIndex !== -1) {
        subjectAreaFilters.value.splice(subjectAreaIndex, 1);
      }
      return;
    }
    case "Staff Group": {
      const staffGroupIndex = staffGroupFilters.value.findIndex(
        (staffGroupFilter) => {
          return staffGroupFilter === filter.filterId;
        }
      );
      if (staffGroupIndex !== -1) {
        staffGroupFilters.value.splice(staffGroupIndex, 1);
      }
      return;
    }
    case "Practice": {
      const practiceIndex = practiceFilters.value.findIndex(
        (practiceFilter) => {
          return practiceFilter === filter.filterId;
        }
      );
      if (practiceIndex !== -1) {
        practiceFilters.value.splice(practiceIndex, 1);
      }
      return;
    }
    case "Patient Age Group": {
      const patientAgeGroupIndex = patientAgeGroupFilters.value.findIndex(
        (patientAgeGroupFilter) => {
          return (
            patientAgeGroupFilter ===
            (filter.filterId.includes("Age 60+") ? "Age 60" : filter.filterId)
          );
        }
      );
      if (patientAgeGroupIndex !== -1) {
        patientAgeGroupFilters.value.splice(patientAgeGroupIndex, 1);
      }
      return;
    }
    case "Date": {
      dateFilters.value = [];
      return;
    }
    default: {
      isFilterApplied.value = false;
      return;
    }
  }
};

const clearFilters = () => {
  subjectAreaFilters.value = [];
  staffGroupFilters.value = [];
  practiceFilters.value = [];
  patientAgeGroupFilters.value = [];
  dateFilters.value = [];
  searchQuery.value = "";
  filterPayload.value = {
    subjectAreaFilters: [],
    staffGroupFilters: [],
    practiceFilters: [],
    patientAgeGroupFilters: [],
    dateFilters: [],
  };
};

watch(
  [
    () => subjectAreaFilters.value,
    () => staffGroupFilters.value,
    () => practiceFilters.value,
    () => patientAgeGroupFilters.value,
    () => dateFilters.value,
  ],
  () => {
    filterPayload.value = {
      subjectAreaFilters: subjectAreaFilters.value,
      staffGroupFilters: staffGroupFilters.value,
      practiceFilters: practiceFilters.value,
      patientAgeGroupFilters: patientAgeGroupFilters.value,
      dateFilters: dateFilters.value,
    };
  },
  { immediate: true, deep: true }
);

const applySearch = (query) => {
  if (query) {
    searchQuery.value = query;
  } else {
    searchQuery.value = "";
  }
  page.value = 1;
};

const practiceFilterChange = (filter) => {
  practicesFilter.value = filter;
};

const subjectGroupFilterChange = (filter) => {
  subjectGroupFilter.value = filter;
};

const staffGroupFilterChange = (filter) => {
  staffGroupFilter.value = filter;
};

const ageGroupFilterChange = (filter) => {
  ageGroupFilter.value = filter;
};

const patientGroupFilterChange = (filter) => {
  patientGroupFilter.value = filter;
};

const tabChanged = (activeTabIndex) => {
  activeTab.value = activeTabIndex;
};

const buttons = ref([
  {
    component: "hq-monitoringOfPracticeFunctionsCreateComplaints",
    params: { isEnabled: 1, isManager: 0, complaintId: 0 },
    text: "Create Complaints",
    bgColor: "bg-yellow",
    textColor: "text-yellow",
    svgIcon: contract_edit,
  },
]);

const complaintAgainstPracticesStats = computed(() => {
  return store.getters["hqComplaints/getComplaintsAgainstPracticesStats"];
});
const subjectGroupStats = computed(() => {
  return store.getters["hqComplaints/getSubjectGroupStats"];
});

const staffGroupStats = computed(() => {
  return [store.getters["hqComplaints/getStaffGroupStats"]];
});

const ageGroupStats = computed(() => {
  return [store.getters["hqComplaints/getAgeGroupStats"]];
});
const patientRelationshipGroupStats = computed(() => {
  return [store.getters["hqComplaints/getPatientRelationshipGroupStats"]];
});

const complaintTypeStats = computed(() => {
  return store.getters["hqComplaints/getComplaintTypeStats"];
});

const complaintData = computed(() => {
  return [
    {
      title: "Upheld Complaints",
      progress: complaintTypeStats.value?.upheld_complaints_count,
      total: complaintTypeStats.value?.complaints_count,
      progressTitle: "Upheld",
      totalComplaintTitle: "Total Complaints",
      color: "rgba(98, 178, 253, 1)",
    },
    {
      title: "Partially Upheld Complaints",
      progress: complaintTypeStats.value?.partially_upheld_complaints_count,
      total: complaintTypeStats.value?.complaints_count,
      progressTitle: "Partially Upheld",
      totalComplaintTitle: "Total Complaints",
      color: "rgba(113, 214, 206, 1)",
    },
    {
      title: "Not Upheld Complaints",
      progress: complaintTypeStats.value?.not_upheld_complaints_count,
      total: complaintTypeStats.value?.complaints_count,
      progressTitle: "Not Upheld Complaints",
      totalComplaintTitle: "Total Complaints",
      color: "rgba(159, 151, 247, 1)",
    },
    {
      title: "Unresolved Complaints",
      progress: complaintTypeStats.value?.unresolved_complaints_count,
      total: complaintTypeStats.value?.complaints_count,
      progressTitle: "Unresolved Complaints",
      totalComplaintTitle: "Total Complaints",
      color: "rgba(249, 155, 171, 1)",
    },
  ];
});

const pageChanged = (pageNumber) => {
  page.value = pageNumber;
};

const AllComplaints = computed(() => {
  return store.getters["hqComplaints/getAllComplaints"];
});

watchEffect(async () => {
  loader.value = true;
  await store
    .dispatch("hqComplaints/fetchAllComplaints", {
      page: page.value,
      status: activeTab.value,
      filters: filterPayload.value,
      search: searchQuery.value,
    })
    .then(() => {
      loader.value = false;
    });
});

watchEffect(async () => {
  await store.dispatch("hqComplaints/fetchSubjectGroupStats", {
    filter: subjectGroupFilter.value,
  });
});

watchEffect(async () => {
  await store.dispatch("hqComplaints/fetchComplaintsAgainstPracticesStats", {
    filter: practicesFilter.value,
  });
});

watchEffect(async () => {
  await store.dispatch("hqComplaints/fetchStaffGroupStats", {
    filter: staffGroupFilter.value,
  });
});

watchEffect(async () => {
  await store.dispatch("hqComplaints/fetchAgeGroupStats", {
    filter: ageGroupFilter.value,
  });
});

watchEffect(async () => {
  await store.dispatch("hqComplaints/fetchPatientRelationshipGroupStats", {
    filter: patientGroupFilter.value,
  });
});

onMounted(async () => {
  loaderProgressBar.value = true;
  await store.dispatch("hqComplaints/fetchComplaintsTypeStats").then(() => {
    loaderProgressBar.value = false;
  });
});
</script>
